<!--
 * @Description: 时间选择器
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-02-04 15:42:57
 * @FilePath: \dog-management-vue\src\components\time-picker\TimePicker.vue
-->
<!-- 官网：https://element-plus.org/zh-CN/component/time-picker.html -->
<template>
  <el-time-picker v-model="modelValueProxy" :placeholder="placeholder" v-bind="$attrs" />
</template>

<script setup lang="ts" name="TimePicker">
import { computed } from 'vue';

const props = defineProps({
  placeholder: { type: String, default: '请选择时间' },
  modelValue: { type: [String, Date, Number], default: null },
});

const emit = defineEmits(['update:modelValue']);

const modelValueProxy = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});
</script>
