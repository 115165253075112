<!--
 * @Description: 日期选择组件
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-02-04 15:01:52
 * @FilePath: \dog-management-vue\src\components\date-picker\DatePicker.vue
-->
<!-- 官网：https://element-plus.org/zh-CN/component/date-picker.html -->
<!-- format：Day.js所支持的所有格式  https://day.js.org/docs/en/display/format#list-of-all-available-formats -->
<template>
  <el-date-picker v-model="modelValueProxy" :placeholder="placeholder" v-bind="$attrs" />
</template>

<script setup lang="ts" name="DatePicker">
import { computed } from 'vue';

const props = defineProps({
  modelValue: { type: [String, Date, Number], default: null }, // 兼容多种日期类型
  placeholder: { type: String, default: '请选择' },
});

const emit = defineEmits(['update:modelValue']);

const modelValueProxy = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});
</script>
