/*
 * @Description:
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-02-10 16:29:53
 * @FilePath: \dog-management-vue\src\components\index.ts
 */
import { App, DefineComponent } from 'vue';
import Table from './table/Table.vue';
import TableAlert from './table-alert/index.vue';
import dialog from './dialog/Dialog.vue';
import RadioGroup from './radio/RadioGroup.vue';
import Select from './select/Select.vue';
import SelectSearch from './select/SelectSearch.vue';
import DatePicker from './date-picker/DatePicker.vue';
import TimePicker from './time-picker/TimePicker.vue';
import Upload from './upload/Upload.vue';
import UploadImg from './upload/UploadImg.vue';
import WangEditor from './wangeditor/WangEditor.vue';

import SelectCity from './select-city/SelectCity.vue';
import OrgSelect from './org-select/OrgSelect.vue';

import DotLine from './dot-line/DotLine.vue';

export default {
  install: (app: App): void => {
    app.component('MTable', Table);
    app.component('TAlert', TableAlert);
    app.component('MDialog', dialog);
    app.component('MRadioGroup', RadioGroup);
    app.component('MSelect', Select);
    app.component('MSelectSearch', SelectSearch);
    app.component('MDatePicker', DatePicker);
    app.component('MTimePicker', TimePicker);
    app.component('MUpload', Upload);
    app.component('MUploadImg', UploadImg);
    app.component('MRichText', WangEditor);
    app.component('SelectCity', SelectCity);
    app.component('OrgSelect', OrgSelect);
    app.component('DotLine', DotLine);
  },
};
