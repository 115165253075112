<template>
  <div style="width: 100%" @click.stop="openDialog">
    <el-input
      type="text"
      v-model="mValue"
      :placeholder="placeholder"
      readonly
      :clearable="clearable"
      :disabled="disabled"
      :size="size"
      :suffix-icon="dialogVisible ? 'ArrowUp' : 'ArrowDown'"
      :validate-event="false"
      @clear="clearInput"
    />
  </div>

  <m-dialog
    v-model="dialogVisible"
    title="选择机构"
    width="700"
    draggable
    overflow
    :footer="false"
    @close="handleCloseDialog"
  >
    <!-- <div class="flex" style="margin-bottom: 10px">
      <el-form :model="queryData" label-position="left" label-width="40px">
        <el-form-item label="名称">
          <el-input v-model="queryData.name" placeholder="名称" clearable />
        </el-form-item>
      </el-form>
      <el-button style="margin-left: 16px" type="primary" :icon="Search" @click="fetchData">查询</el-button>
    </div> -->
    <m-table :dataList="tableData" :total="total" :loading="tableLoading" @size-change="sizeChange">
      <el-table-column prop="name" label="名称" align="center" />
      <el-table-column prop="address" label="地址" />
      <el-table-column label="操作" align="center" width="90">
        <template #default="scope">
          <el-button link type="primary" size="small" @click="handleSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </m-table>
  </m-dialog>
</template>

<script lang="ts">
enum ESize {
  Default = 'default',
  Small = 'small',
  Large = 'large',
}
export default {};
</script>

<script setup lang="ts">
import { ArrowDown, ArrowUp, Search } from '@element-plus/icons-vue';
import { onMounted, PropType, reactive, ref, watch } from 'vue';
import { PageModal } from '@/api/index';
// import { queryOrgModal, getOrgListByPage } from '@/api/organize/governmentAPI';
import { ElMessage } from 'element-plus';

const props = defineProps({
  modelValue: { required: true, type: [String, Number], default: 0 },

  placeholder: { type: String, default: '请选择' },

  clearable: { type: Boolean, default: false },

  disabled: { type: Boolean, default: false },

  size: {
    type: String as PropType<ESize>,
    default: ESize.Default,
    validator: (value: string) => Object.values(ESize).includes(value as ESize),
  },
});

const eventEmits = defineEmits(['update:modelValue', 'select']);

const mValue = ref<string>('');

const dialogVisible = ref<boolean>(false);

// const queryData = reactive<queryOrgModal>(new queryOrgModal());
const queryData = reactive({});
const pageData = reactive(new PageModal());
const tableLoading = ref<boolean>(false);
const tableData = ref<any[]>([]);
const total = ref<number>(0);

watch(
  () => props.modelValue,
  (val) => {
    if (!val) {
      mValue.value = '';
    }
  },
  { immediate: true }
);

const openDialog = () => {
  dialogVisible.value = true;
};

const handleCloseDialog = () => {
  dialogVisible.value = false;
};

const handleSelect = (row: any) => {
  mValue.value = row.name;
  eventEmits('update:modelValue', row.id);
  eventEmits('select', { id: row.id, type: row.type, name: row.name });
  handleCloseDialog();
};

const sizeChange = (pageSize: number, currentPage: number) => {
  pageData.size = pageSize;
  pageData.page = currentPage;
  fetchData();
};

const clearInput = () => {
  mValue.value = '';
  eventEmits('update:modelValue', 0);
  eventEmits('select', null);
};

const fetchData = async () => {
  // tableLoading.value = true;
  // try {
  //   queryData.type = 2;
  //   const res: any = await getOrgListByPage(pageData, queryData);
  //   const { data } = res;
  //   tableData.value = data.data;
  //   total.value = data.total;
  // } catch (error: any) {
  //   ElMessage.error(error.message);
  // } finally {
  //   tableLoading.value = false;
  // }
};

onMounted(() => {
  fetchData();
});
</script>

<style scoped lang="less"></style>
