/*
 * @Description:
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-02-16 14:47:37
 * @FilePath: \dog-management-vue\src\router\index.ts
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import AdminMainLayout from '@/layouts/MainLayout.vue';
import dynamicRoutes from './routes/routes';
import { useStore } from 'vuex';
import { ERole } from '@/utils/type';

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/login', children: [] },
  {
    path: '/login',
    component: () => import('@/views/login/Login.vue'),
    name: 'Login',
  },
  {
    path: '/home',
    component: AdminMainLayout,
    meta: { title: '首页', icon: 'home-outlined' },
    children: [...dynamicRoutes.value],
  },
  {
    path: '/dataScreen',
    component: () => import('@/views/data-screen/index.vue'),
    name: 'DataScreen',
    meta: { has: [ERole.super_admin, ERole.lawyer] },
  },
  { path: '/', meta: { title: '', hidden: true }, redirect: '/home' },
  { path: '/404', meta: { title: '', hidden: true }, component: () => import('@/views/404.vue') },
  // *为通配符，匹配所有路径
  { path: '/:catchAll(.*)', redirect: '/404', meta: { title: '', icon: '' }, children: [] },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == '/login') return next();
  if (to.path == '/404') return next();

  const token = window.sessionStorage.getItem('xtoken');
  if (token) {
    if ((to.meta as any).has) {
      const store = useStore();
      // const userRoles = Object.values(store.state?.user.role)
      //   .filter(Boolean)
      //   .map((role) => role as any);
      const userRoles = store.state.user.role;

      const bool = userRoles.some((userRole: any) => (to.meta as any).has.includes(userRole));
      if (bool) next();
      else next({ path: `${from.path}` });
    } else {
      next();
    }
  } else next({ path: '/login' });
});

export default router;
