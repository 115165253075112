<!--
 * @Description: 
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-02-10 11:29:06
 * @FilePath: \dog-management-vue\src\layouts\components\Footer.vue
-->
<template>
  <el-footer class="mc-foot">
    <div>{{ title }} {{ copyright }} {{ company }}{{ reserved }}</div>
    <!-- <div class="link" @click="linkToRecord">{{ record }}</div> -->
  </el-footer>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';

const title = ref(process.env.VUE_APP_TITLE);
const copyright = process.env.VUE_APP_COPYRIGHT;
const company = process.env.VUE_APP_US;
const record = process.env.VUE_APP_RECORD;

const store = useStore();

const reserved = computed(() => {
  const role = store.state.user.role;

  if (role && role.lawyer) {
    return '提供技术支持';
  }

  return '版权所有';
});

const linkToRecord = () => {
  window.open('https://beian.miit.gov.cn/', '_blank');
};

onMounted(() => {
  const role = store.state.user.role;
  if (role && role.lawyer) {
    title.value = '城市犬只管理平台';
  } else if (role && (role.admin || role.super_admin)) {
    title.value = '数字犬证管理系统';
  } else {
    title.value = '数字犬证服务平台';
  }
});
</script>

<style scoped>
.mc-foot {
  line-height: var(--el-footer-height);
  text-align: center;
  padding: 0;
  background-color: #f8f8f8;
  border-top: 1px solid #efeded;
  display: flex;
  justify-content: center;
}

.mc-foot .link {
  margin-left: 10px;
  cursor: pointer;
}
</style>
