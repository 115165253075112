<!--
 * @Description: 
 * @Date: 2025-02-10 15:54:32
 * @LastEditTime: 2025-02-10 16:53:30
 * @FilePath: \dog-management-vue\src\components\dot-line\DotLine.vue
-->
<template>
  <canvas id="J_dotLine"></canvas>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import Dotline from '../../assets/js/dotline.js';

const props = defineProps({
  //画布宽
  canvasWidth: { type: Number, default: 800 },
  //画布高
  canvasHeight: { type: Number, default: 400 },
  //点的个数
  dotCount: { type: Number, default: 100 },
  color: { type: String, default: '#409eff' },
});

const option = ref({
  dom: 'J_dotLine', //画布id
  cw: props.canvasWidth,
  ch: props.canvasHeight,
  ds: props.dotCount,
  r: 2, //圆点半径
  cl: props.color,
  dis: 100, //触发连线的距离
});

onMounted(() => {
  new Dotline(option.value).start();
});
</script>

<style scoped></style>
