/*
 * @Description:
 * @Date: 2024-09-23 11:28:34
 * @LastEditTime: 2025-02-13 17:00:15
 * @FilePath: \dog-management-vue\src\utils\type.ts
 */
export interface IRole {
  super_admin?: 'SUPER_ADMIN';
  admin?: 'ADMIN';
  lawyer?: 'LAWYER';
  hospital?: 'HOSPITAL';
  country_recorder?: 'COUNTRY_RECORDER';
  owner?: 'OWNER';
  guest?: 'GUEST';
}

export enum ERole {
  super_admin = 'SUPER_ADMIN',
  admin = 'ADMIN',
  lawyer = 'LAWYER',
  country_recorder = 'COUNTRY_RECORDER',
  hospital = 'HOSPITAL',
  owner = 'OWNER',
  guest = 'GUEST',
}

export const roleMap: Record<string, string> = {
  guest: '普通用户',
  owner: '犬主',
  hospital: '宠物医院',
  country_recorder: '犬只登记员',
  lawyer: '执法员',
  admin: '管理员',
  super_admin: '超管',
};

export const roleValue: Record<string, string> = {
  GUEST: '普通用户',
  OWNER: '犬主',
  HOSPITAL: '宠物医院',
  COUNTRY_RECORDER: '犬只登记员',
  LAWYER: '执法员',
  ADMIN: '管理员',
  SUPER_ADMIN: '超管',
};

export enum EBlogCategory {
  dogtips = '养犬贴士', //养犬贴士
  notice = '通知公告', //通知公告
  default = '默认',
}
