/*
 * @Description:
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-02-12 09:54:16
 * @FilePath: \dog-management-vue\src\utils\request.ts
 */
import router from '@/router';
import store from '@/store';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const service = axios.create({
  baseURL: process.env.VUE_APP_PROXY_TARGET,
  timeout: 8000,
  headers: {
    'Content-Type': 'application/json', // 默认为JSON
    Accept: 'application/json',
  },
});

service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    const token = window.sessionStorage.getItem('xtoken');
    if (token) {
      config.headers['XTOKEN'] = `${token}`;
    }

    const expireTime = store.state.user.expireTime;
    const currentTime = new Date().getTime();

    if (expireTime && currentTime > expireTime) {
      store.commit('user/logout');
      router.push('/login');
      return Promise.reject(new Error('登录已超时，请重新登录'));
    }

    // 更新 expireTime 为当前时间+2 小时
    const newExpireTime = currentTime + 2 * 60 * 60 * 1000;
    store.commit('user/setExpireTime', newExpireTime);

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

service.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status == 200) {
      const { data } = response;
      if (data.code === 0) {
        // 请求成功
        return data;
      } else {
        // 请求出错
        return Promise.reject(new Error(data.detail));
      }
    } else return Promise.reject(new Error(response.status + '错误'));
  },
  (error: AxiosError) => {
    // 超出 2xx 范围的状态码都会触发该函数。
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      return Promise.reject(new Error('请求超时，请稍后再试'));
    }

    if (!error.response) {
      if (error.message) return Promise.reject(new Error(error.message));
      else return Promise.reject(new Error('网络错误')); // 网络错误或服务器没有返回响应
    }
    const { response } = error;

    if (response) {
      const _data: any = response.data;
      return Promise.reject(new Error(_data?.detail));
    }
  }
);

export default service;
