import { ref } from 'vue';
import { ERole } from '@/utils/type';

const routes = ref([
  {
    path: '/home',
    component: () => import('@/views/home.vue'),
    name: 'Home',
    meta: { title: '首页', noCache: true, icon: 'House' },
  },
  {
    path: '/township-dog',
    name: 'TownshipDogList',
    meta: {
      title: '农村犬只管理',
      noCache: true,
      icon: 'Tools',
      has: [ERole.admin, ERole.super_admin, ERole.country_recorder],
    },
    children: [
      {
        path: '/township-dog-list',
        component: () => import('@/views/township-dog/township-dog-list/List.vue'),
        name: 'TownshipDogList',
        meta: {
          title: '犬只列表',
          noCache: true,
          icon: 'Memo',
          has: [ERole.admin, ERole.super_admin, ERole.country_recorder],
        },
      },
    ],
  },
  {
    path: '/audit-management',
    name: 'AuditManagement',
    meta: { title: '审核管理', noCache: true, icon: 'SetUp', has: [ERole.lawyer, ERole.super_admin, ERole.admin] },
    children: [
      {
        path: '/license-audit',
        component: () => import('@/views/dog-license/audit/Audit.vue'),
        name: 'LicenseAudit',
        meta: { title: '犬证申请审核', noCache: true, icon: 'List', has: [ERole.lawyer, ERole.super_admin] },
      },
    ],
  },
  {
    path: '/dog-management',
    name: 'DogManagement',
    meta: { title: '城市犬只管理', noCache: true, icon: 'SetUp', has: [ERole.admin, ERole.super_admin] },
    children: [
      {
        path: '/license-list',
        component: () => import('@/views/dog-license/management-list/List.vue'),
        name: 'LicenseList',
        meta: { title: '犬证管理', noCache: true, icon: 'List', has: [ERole.admin, ERole.lawyer] },
      },
      {
        path: '/prohibited-dog',
        component: () => import('@/views/prohibited-dog/index.vue'),
        name: 'ProhibitedDog',
        meta: { title: '禁养犬只管理', noCache: true, icon: 'List', has: [ERole.admin, ERole.super_admin] },
      },
    ],
  },
  {
    path: '/law-enforcement-management',
    name: 'LawEnforcementManagement',
    meta: { title: '执法管理', noCache: true, icon: 'Magnet', has: [ERole.lawyer] },
    children: [
      {
        path: '/alarm-info',
        component: () => import('@/views/law-management/alarm-information/index.vue'),
        name: 'AlarmInfo',
        meta: { title: '警报信息', noCache: true, icon: 'List', has: [ERole.lawyer] },
      },
      {
        path: '/case-info',
        component: () => import('@/views/law-management/case-information/index.vue'),
        name: 'CaseInfo',
        meta: { title: '案件信息', noCache: true, icon: 'List', has: [ERole.lawyer] },
      },
    ],
  },
  {
    path: '/confinement-management',
    name: 'ConfinementManagement',
    meta: { title: '收容管理', noCache: true, icon: 'OfficeBuilding', has: [ERole.lawyer] },
    children: [
      {
        path: '/house',
        component: () => import('@/views/house-management/house/index.vue'),
        name: 'House',
        meta: { title: '收容管理', noCache: true, icon: 'List', has: [ERole.lawyer] },
      },
    ],
  },
  {
    path: '/article-management',
    name: 'ArticleManagement',
    meta: { title: '文章管理', noCache: true, icon: 'Tickets', has: [ERole.admin, ERole.super_admin] },
    children: [
      {
        path: '/article',
        component: () => import('@/views/article-management/notice-list/List.vue'),
        name: 'ArticleList',
        meta: { title: '通知公告管理', noCache: true, icon: 'Document', has: [ERole.admin, ERole.super_admin] },
      },
      {
        path: '/questions',
        component: () => import('@/views/article-management/common-questions/index.vue'),
        name: 'Question',
        meta: { title: '养犬贴士管理', noCache: true, icon: 'Collection', has: [ERole.admin, ERole.super_admin] },
      },
    ],
  },
  // {
  //   path: '/personal-setting',
  //   name: 'FacilityManagement',
  //   meta: { title: '个人设置', noCache: true, icon: 'Discount' },
  //   component: () => import('@/views/personal-setting/Setting.vue'),
  // },
  {
    path: '/vaccination-list',
    component: () => import('@/views/vaccination-management/List.vue'),
    name: 'VaccinationList',
    meta: { title: '疫苗管理', noCache: true, icon: 'Help', has: [ERole.admin, ERole.super_admin] },
  },
  {
    path: '/admin-dog-management',
    name: 'AdminDogManagement',
    meta: { title: '犬只管理(管理员)', noCache: true, icon: 'Help', has: [ERole.admin, ERole.super_admin] },
    children: [
      {
        path: '/dog-license-number',
        component: () => import('@/views/admin-dog-management/dog-license-number/SetDogIDCard.vue'),
        name: 'DogLicenseNumber',
        meta: { title: '更改犬只编号', noCache: true, icon: 'Postcard', has: [ERole.admin, ERole.super_admin] },
      },
    ],
  },
  {
    path: '/system-management',
    name: 'SystemManagement',
    meta: { title: '系统管理', noCache: true, icon: 'Setting', has: [ERole.admin, ERole.super_admin] },
    children: [
      {
        path: '/users',
        component: () => import('@/views/system-management/user/User.vue'),
        name: 'User',
        meta: { title: '用户管理', noCache: true, icon: 'User', has: [ERole.admin, ERole.super_admin] },
      },
      {
        path: '/organize-tree',
        component: () => import('@/views/system-management/organize/TreeList.vue'),
        name: 'OrganizeTree',
        meta: { title: '组织管理', noCache: true, icon: 'Operation', has: [ERole.admin, ERole.super_admin] },
      },
      {
        path: '/hospital',
        component: () => import('@/views/system-management/hospital/HospitalList.vue'),
        name: 'Hospital',
        meta: { title: '办证门店', noCache: true, icon: 'School', has: [ERole.admin, ERole.super_admin] },
      },
      {
        path: '/law-agency',
        component: () => import('@/views/system-management/law-agency/AgencyList.vue'),
        name: 'LawAgency',
        meta: { title: '执法机构', noCache: true, icon: 'DataBoard', has: [ERole.admin, ERole.super_admin] },
      },
      {
        path: '/roles',
        component: () => import('@/views/system-management/role/index.vue'),
        name: 'Role',
        meta: { title: '角色管理', noCache: true, icon: 'Avatar', has: [ERole.admin, ERole.super_admin] },
      },
      // {
      //   path: '/slideshow',
      //   component: () => import('@/views/system-management/mini-program/user-agreement/UserAgreement.vue'),
      //   name: 'Slideshow',
      //   meta: { title: '小程序用户协议', noCache: true, icon: 'List', has: [ERole.admin] },
      // },
      {
        path: '/feat',
        component: () => import('@/views/system-management/super-admin/Feat.vue'),
        name: 'Feat',
        meta: { title: '数据维护', noCache: true, icon: 'Coin', has: [ERole.super_admin] },
      },
    ],
  },
]);

export default routes;
