/*
 * @Description:
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-02-06 11:03:06
 * @FilePath: \dog-management-vue\src\api\login\loginAPI.ts
 */
import request from '@/utils/request';

export class loginModal {
  account: string = '';
  password: string = '';
}

// 登录
export const loginUser = (par: any) => {
  // return request.post('/login', null, {
  //   params: par,
  // });
  // return request({
  //   url: '/login',
  //   method: 'post',
  //   params: par,
  //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // 可显式设置为表单数据格式
  // });
  return request({
    url: '/manager/user/login',
    method: 'post',
    data: par,
  });
};
