<!--
 * @Description: 城市选择器
 * @Date: 2024-11-19 09:43:22
 * @LastEditTime: 2025-03-10 16:11:23
 * @FilePath: \dog-management-vue\src\components\select-city\SelectCity.vue
-->
<template>
  <el-select
    v-model="modelValueProxy"
    :placeholder="placeholder"
    no-data-text="暂无数据"
    no-match-text="暂未找到相关数据"
    v-bind="$attrs"
    clearable
  >
    <el-option :value="item.area_code" :label="item.name" v-for="(item, index) in options" :key="index"> </el-option>
  </el-select>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus';
import { computed, onMounted, ref, watch } from 'vue';
import { getAreaByParentCode } from '@/api/index';

const options = ref<any[]>([]);

const props = defineProps({
  placeholder: { type: String, default: '请选择' },

  modelValue: { required: true, type: Number, default: null },

  requestCode: { type: Number || null, default: 450000000000 },

  immReq: { type: Boolean, default: true }, //是否在组件挂载时立即请求数据
});

const emitEvents = defineEmits(['update:modelValue']);

const modelValueProxy = computed({
  get: () => props.modelValue,
  set: (val) => emitEvents('update:modelValue', val),
});

watch(
  () => props.requestCode,
  (val: number | null) => {
    if (val) {
      getAreaData();
    } else {
      options.value = [];
      emitEvents('update:modelValue', null);
    }
  }
);

const getAreaData = async () => {
  try {
    const res: any = await getAreaByParentCode(props.requestCode);
    if (res.code === 0) {
      options.value = res.data;
    } else {
      ElMessage.error(res.detail);
    }
  } catch (error: any) {
    ElMessage.error(error.message);
  }
};

const clearValue = (): void => {
  emitEvents('update:modelValue', null);
};

onMounted(() => {
  if (props.immReq) {
    getAreaData();
  }
});
</script>

<style scoped></style>
