<template>
  <el-header class="mc-header">
    <el-row style="width: 100%; height: 100%">
      <el-col :span="12">
        <div class="header-left">
          <img v-if="!isDefault" class="police-logo" src="../../assets/images/138ffr.png" />
          <img v-else class="mc-logo" src="../../assets/images/logofpp.png" />
          <span class="title">{{ title }}</span>
        </div>
      </el-col>

      <el-col
        :span="12"
        style="text-align: right; padding-right: 20px; display: flex; justify-content: flex-end; align-items: center"
      >
        <el-dropdown size="default" @command="handleCommand">
          <span class="el-dropdown-link drop-txt">
            您好，{{ userName }}
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :command="ECommand.logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-header>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ERole } from '@/utils/type';

const title = ref(process.env.VUE_APP_TITLE);

const isDefault = ref<boolean>(true);

enum ECommand {
  logout = '退出登录',
}

const store = useStore();
const router = useRouter();

const userName = computed(() => {
  const role = store.state.user.role;
  const userName = store.state.user.userName;

  if (role.includes(ERole.super_admin)) return '超管';

  if (role.includes(ERole.lawyer)) return '执法员' + userName.slice(-4);

  return userName || '未命名';
});

const handleCommand = (command: ECommand) => {
  if (command == ECommand.logout) {
    store.commit('user/logout');
    router.push('/login');
  }
};

onMounted(() => {
  const role = store.state.user.role;
  role.includes(ERole.lawyer)
    ? ((title.value = '城市犬只管理平台'), (isDefault.value = false))
    : role.includes(ERole.admin)
    ? (title.value = '数字犬证管理系统')
    : role.includes(ERole.super_admin)
    ? (title.value = '数字犬证管理系统')
    : (title.value = '数字犬证服务平台');
});
</script>

<style scoped lang="less">
.mc-header {
  height: 76px;
  padding: 0;
  background-color: #1658a0;
  color: #fff;
}

.header-left {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;

  .police-logo {
    height: 68px;
  }
  .mc-logo {
    height: 58px;
  }

  .title {
    padding-left: 10px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 3px;
  }
}
.el-dropdown {
  color: inherit;
}
.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.drop-txt {
  display: flex;
  align-items: center;
}
</style>
