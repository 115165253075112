/*
 * @Description:
 * @Date: 2024-09-21 22:42:58
 * @LastEditTime: 2025-03-20 11:35:04
 * @FilePath: \dog-management-vue\src\api\index.ts
 */
import request from '@/utils/request';
import { loginUser } from './login/loginAPI';

/**
 * @description: 页码model
 * @return {*}
 */
export class PageModal {
  page: number = 1;
  size: number = 30;
}

/**
 * @description: 获取地区下一级所有子地区
 * @param {number} code
 * @return {*}
 */
export const getAreaByParentCode = (code: number = 450000000000) => {
  return request.get('/gov/area/children?parent_area_code=' + code);
};

export default {
  loginUser,
};
